@import '../_common/c-package';

.c-package {
    border: 1px solid $gray-400;
    &.is-active {
        border-color: $primary;
    }
    &:hover {
        box-shadow: 0 3px 15px 0 rgba(0,0,0,.25);
    }
}
.c-package__img {
    background-color: $gray-100;
}
.c-package__code {
    color: $gray-700;
    font-size: 1rem;
    font-weight: $font-weight-normal;
}
.c-package__title {
    margin: 0;
}
.c-package__price {
    font-size: 1.125rem;
    font-weight: $font-weight-bold;
}

.c-pkg__title {
    font-size: .875rem;
    font-weight: $font-weight-normal;
    .c-pkg.is-active & {
        font-weight: $font-weight-bold;
    }
}