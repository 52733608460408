@import '../_common/c-grade';

.c-grade {
    border: 1px solid $gray-100;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,.1);
}
.c-grade__img {
    background-color: $gray-100;
}
.c-grade__title {
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
}
.c-grade__price {
    .c-price {
        font-size: 1.25rem;
        font-weight: $font-weight-bold;
    }
}
.c-grade__tech {
    font-size: .875rem;
    font-weight: $font-weight-bold;
}