@import '../_common/c-steps';

.c-steps__nav {
    border-top: 1px solid $gray-400;
    border-bottom: 1px solid $gray-400;
    font-size: .875rem;
    @include media-breakpoint-up(lg) {
        font-size: .75rem;
    }
}
.c-steps__link {
    border-top: 1px solid $gray-900;
    &:hover,
    &.active {
        font-weight: $font-weight-bold;
    }
    @include media-breakpoint-up(lg) {
        flex: 1;
        justify-content: center;
        padding: 0 20px 0 5px;
        border-top: 0;
        background-color: $gray-900;
        &::before,
        &::after {
            position: absolute;
            left: 0;
            top: 50%;
            display: block;
            width: 0;
            height: 0;
            margin-top: -50px;
            margin-left: -21px;
            border: 1px solid transparent;
            border-width: 50px 0 50px 20px;
            content: "";
        }
        &::before {
            z-index: 1;
            margin-left: -20px;
            border-left-color: $gray-400;
        }
        &::after {
            z-index: 2;
            border-left-color: $gray-900;
        }
        &.active {
          background-color: $black;
          &::before {
              border-top-color: $black;
              border-bottom-color: $black;
          }
          + .c-steps__link {
              &::before {
                  border-top-color: $gray-900;
                  border-bottom-color: $gray-900;
              }
              &::after {
                  border-left-color: $black;
              }
          }
        }
    }
}
.c-steps__text {
    @include media-breakpoint-up(lg) {
        margin: 0 30px;
        border: 3px solid transparent;
        .active > & {
            border-bottom-color: $primary;
        }
    }
}