@import '../_common/c-engine';

.c-engine {
    color: $gray-900;
}
.c-engine__title {
    font-size: 1.25rem;
    font-weight: $font-weight-bold;
}
.c-engine__price {
    .c-price {
        font-size: 1.25rem;
        font-weight: $font-weight-bold;
    }
}
.c-engine__tech {
    .c-tech__title {
        padding: 0;
        border: 0;
        color: inherit;
        font: inherit;
    }
}
.c-engine__descr {
    color: $gray-700;
    font-size: .6875rem;
}
.c-engine__check {
    .form-check-input {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
        font-size: inherit;
    }
}