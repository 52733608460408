@import '../_common/c-header';

.c-header__title {
    font-size: 1.125rem;
    line-height: 1.45;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
    }
}