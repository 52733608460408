@import '../_common/buttons';

.btn {
    border-radius: 0;
    font-weight: 700;
    text-transform: lowercase;
}

.btn-light {
    border-color: $secondary;
    background-color: $white;
    color: $secondary;
    &:hover,
    &:focus,
    &:active {
        background-color: $secondary;
        color: $white;
    }
}
.btn-primary {
    color: $black;
    &:hover,
    &:focus,
    &:active {
        color: $black;
        border-color: $primary-light;
        background-color: $primary-light;
    }
}