@font-face {
    font-family: "Renault";
    src: url('../../fonts/theme-renault/renault-regular.woff2') format("woff2"), url('../../fonts/theme-renault/renault-regular.woff') format("woff");
    font-weight: 400;
    font-display: swap;
    unicode-range: u+000-20cf
}

@font-face {
    font-family: "Renault";
    src: url('../../fonts/theme-renault/renault-bold.woff2') format("woff2"), url('../../fonts/theme-renault/renault-bold.woff') format("woff");
    font-weight: 700;
    font-display: swap;
    unicode-range: u+000-20cf
}