@import '../_common/c-selection';

.c-selection__title {
    font-size: 0.875rem;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}
.c-selection__diff {
    font-size: 0.875rem;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}
